import './App.css';
import Keyboard from './components/keyboard/Keyboard'

function App() {
  
  return (
    <div>
      <div className="header">
        scrynth
      </div>
      <Keyboard />
    </div>
  );

}

export default App;
